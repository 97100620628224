import {
    AccountBalance,
    Apps,
    Article,
    BlurCircular,
    ControlPointDuplicate,
    DocumentScannerOutlined,
    GroupWorkOutlined,
    HomeOutlined,
    IntegrationInstructions,
    MailOutline,
    Map,
    MonetizationOn,
    Payments,
    People,
    RequestQuote,
    Sensors,
    SettingsInputAntenna,
    Speed,
    WbSunny,
    WifiTetheringOff,
} from '@mui/icons-material'
import {
    Autocomplete,
    Box,
    CircularProgress,
    Divider,
    List,
    ListItem,
    Paper,
    styled,
    useTheme,
} from '@mui/material'
import React, { useContext } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { ACTIONS } from 'src/auth/utils/defineAbilityFor'
import Scrollbar from 'switch-fe-shared/components/Scrollbar/Scrollbar'
import { NetworkState } from 'src/constants/network-state'
import { SidebarContext } from 'src/context/SidebarContext'
import { AbilityContext, Can } from 'src/context/canContext'
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks'
import { updateSelectedApplicationGroupId } from 'src/redux/application-groups/application-group.slice'
import { updateSelectedApplicationId } from 'src/redux/applications/applicationSlice'
import pages from '../../../../router/routes'
import { ROLES } from 'src/constants'
import { useAbility } from '@casl/react'
import { Application } from 'switch-shared/modules/crud/application/application.entity'
import { ApplicationGroup } from 'switch-shared/modules/crud/application-group/application-group.entity'
import Button from 'switch-fe-shared/components/Buttons/Button'
import TextField from 'switch-fe-shared/components/TextInput/Textfield'

const MenuWrapper = styled(Box)(
    ({ theme }: { theme: any }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
)

const SubMenuWrapper = styled(Box)(
    ({ theme }: { theme: any }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.palette.text.primary};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.sidebar.menuItemIconColor};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.sidebar.menuItemIconColor};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                    'transform',
                    'opacity',
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
)

function SidebarMenu() {
    const { closeSidebar } = useContext(SidebarContext)
    const dispatch = useAppDispatch()
    const ability = useAbility(AbilityContext)

    const theme = useTheme()
    const {
        applicationGroups,
        locallySelectedApplicationGroupId,
        applicationGroupNetworkStatus,
    } = useAppSelector((state) => state.applicationGroup)
    const {
        applications,
        locallySelectedApplicationId,
        applicationNetworkStatus,
    } = useAppSelector((state) => state.application)

    return (
        <>
            <MenuWrapper
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <SubMenuWrapper>
                    <List component="div">
                        <ListItem component="div">
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/home"
                                startIcon={<HomeOutlined />}
                                variant="text"
                            >
                                Dashboard
                            </Button>
                        </ListItem>
                    </List>
                </SubMenuWrapper>

                <Scrollbar style={{ width: '100%', flex: 1 }} autoHide={false}>
                    <SubMenuWrapper>
                        <List component="div">
                            <Divider
                                sx={{
                                    m: theme.spacing(2),
                                    background:
                                        theme.colors.alpha.trueWhite[10],
                                }}
                            />
                            {applicationGroups.length > 1 && (
                                <>
                                    <Can I={ACTIONS.MANAGE} a="all">
                                        <ListItem component="div">
                                            <Button
                                                disableRipple
                                                component={RouterLink}
                                                onClick={closeSidebar}
                                                to={pages.applicationGroup.path}
                                                startIcon={<BlurCircular />}
                                            >
                                                Application Groups
                                            </Button>
                                        </ListItem>
                                        <ListItem component="div">
                                            <Button
                                                disableRipple
                                                component={RouterLink}
                                                onClick={closeSidebar}
                                                to={
                                                    pages.emailIntegrationLogs
                                                        .path
                                                }
                                                startIcon={<MailOutline />}
                                            >
                                                Email Integration Logs
                                            </Button>
                                        </ListItem>
                                        <ListItem component="div">
                                            <Button
                                                disableRipple
                                                component={RouterLink}
                                                onClick={closeSidebar}
                                                to={
                                                    pages.unallocatedMeters.path
                                                }
                                                startIcon={<WifiTetheringOff />}
                                            >
                                                Unallocated devices
                                            </Button>
                                        </ListItem>
                                    </Can>
                                    <ListItem>
                                        <Autocomplete
                                            options={applicationGroups ?? []}
                                            disableClearable
                                            loading={
                                                applicationGroupNetworkStatus.applicationGroups !==
                                                NetworkState.SUCCESS
                                            }
                                            value={
                                                applicationGroupNetworkStatus.applicationGroups !==
                                                NetworkState.SUCCESS
                                                    ? { _id: '', name: '' }
                                                    : (
                                                          applicationGroups ??
                                                          []
                                                      ).find(
                                                          (
                                                              element: ApplicationGroup
                                                          ) =>
                                                              element._id ===
                                                              locallySelectedApplicationGroupId
                                                      )
                                            }
                                            sx={{ flex: 1, mt: 2, mb: 1 }}
                                            getOptionLabel={(option: any) =>
                                                option.name
                                            }
                                            onChange={(
                                                _e: any,
                                                newValue: any
                                            ) => {
                                                dispatch(
                                                    updateSelectedApplicationGroupId(
                                                        newValue._id
                                                    )
                                                )
                                            }}
                                            PaperComponent={({ children }) => (
                                                <Paper elevation={4}>
                                                    {children}
                                                </Paper>
                                            )}
                                            ListboxProps={{
                                                sx: {
                                                    '::-webkit-scrollbar': {
                                                        width: '12px',
                                                        backgroundColor:
                                                            theme.colors.neutral
                                                                .grey[40],
                                                    },
                                                    '::-webkit-scrollbar-thumb':
                                                        {
                                                            borderRadius:
                                                                '10px',
                                                            backgroundColor:
                                                                theme.colors
                                                                    .primary
                                                                    .main,
                                                            '::-webkit-box-shadow':
                                                                'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                                                        },
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Group"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {applicationGroupNetworkStatus.applicationGroups !==
                                                                NetworkState.SUCCESS ? (
                                                                    <CircularProgress
                                                                        color="inherit"
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                ) : null}
                                                                {
                                                                    params
                                                                        .InputProps
                                                                        .endAdornment
                                                                }
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: '#303030',
                                                        },
                                                        classes: {
                                                            focused: 'focused',
                                                            shrink: 'shrink',
                                                        },
                                                    }}
                                                    sx={{
                                                        width: '97%',
                                                        color: 'white',
                                                        style: { fontSize: 15 },
                                                        Input: {
                                                            color: '#303030',
                                                        },
                                                        '& fieldset': {
                                                            // paddingLeft: (theme) => theme.spacing(2.5),
                                                            borderRadius:
                                                                '20px',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </ListItem>
                                </>
                            )}
                            <Can I={ACTIONS.READ} a="Applications">
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to={pages.application.path}
                                        startIcon={<Apps />}
                                    >
                                        Applications
                                    </Button>
                                </ListItem>
                            </Can>
                            {/* TODO the bastard clients won't want their users to see this but 'read' users is required for the mobile app */}
                            <Can I={ACTIONS.MANAGE} a="Users">
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to={pages.users.path}
                                        startIcon={<People />}
                                    >
                                        Users
                                    </Button>
                                </ListItem>
                            </Can>
                            <Can I={ACTIONS.READ} a="Reports">
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to={pages.reports.path}
                                        startIcon={<Article />}
                                    >
                                        Reporting
                                    </Button>
                                </ListItem>
                            </Can>
                        </List>
                    </SubMenuWrapper>

                    <Divider
                        sx={{
                            mx: theme.spacing(2),
                            my: theme.spacing(1),
                            background: theme.colors.alpha.trueWhite[10],
                        }}
                    />

                    <ListItem>
                        <Autocomplete
                            disablePortal
                            options={applications ?? []}
                            loading={
                                applicationNetworkStatus.applications !==
                                NetworkState.SUCCESS
                            }
                            value={
                                applicationNetworkStatus.applications !==
                                NetworkState.SUCCESS
                                    ? { _id: '', name: '' }
                                    : (applications ?? []).find(
                                          (element: Application) =>
                                              element._id ===
                                              locallySelectedApplicationId
                                      )
                            }
                            sx={{ flex: 1, mt: 2 }}
                            getOptionLabel={(option: any) => option.name}
                            disableClearable
                            onChange={(_e: any, newValue: any) => {
                                dispatch(
                                    updateSelectedApplicationId(newValue._id)
                                )
                            }}
                            PaperComponent={({ children }) => (
                                <Paper elevation={4}>{children}</Paper>
                            )}
                            ListboxProps={{
                                sx: {
                                    '::-webkit-scrollbar': {
                                        width: '12px',
                                        backgroundColor:
                                            theme.colors.neutral.grey[40],
                                    },
                                    '::-webkit-scrollbar-thumb': {
                                        borderRadius: '10px',
                                        backgroundColor:
                                            theme.colors.primary.main,
                                        '::-webkit-box-shadow':
                                            'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                                    },
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Application"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {applicationNetworkStatus.applications !==
                                                NetworkState.SUCCESS ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#303030',
                                        },
                                        classes: {
                                            focused: 'focused',
                                            shrink: 'shrink',
                                        },
                                    }}
                                    sx={{
                                        color: 'white',
                                        style: { fontSize: 15 },
                                        Input: { color: '#303030' },
                                        '& fieldset': {
                                            // paddingLeft: (theme) => theme.spacing(2.5),
                                            borderRadius: '20px',
                                        },
                                    }}
                                />
                            )}
                        />
                    </ListItem>

                    {locallySelectedApplicationId !== 'All' &&
                        locallySelectedApplicationId !== undefined && (
                            <>
                                {/* <ListItemButton
              onClick={() => setOpenManagement(!openManagement)}
            >
              <ListSubheader component='div' disableSticky >
                Management
              </ListSubheader>
              {openManagement ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />}
            </ListItemButton> */}
                                {/* <Collapse in={openManagement} timeout='auto' unmountOnExit> */}
                                <SubMenuWrapper>
                                    <List component="div">
                                        <Can I={ACTIONS.READ} a="Accounts">
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.accounts.path}
                                                    startIcon={
                                                        <AccountBalance />
                                                    }
                                                >
                                                    Accounts
                                                </Button>
                                            </ListItem>
                                        </Can>
                                        <Can
                                            I={ACTIONS.READ}
                                            a="ApplicationUtilities"
                                        >
                                            <ListItem component="div">
                                                <Button
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.utility.path}
                                                    startIcon={<WbSunny />}
                                                    disabled={
                                                        !(
                                                            locallySelectedApplicationId !==
                                                                'All' &&
                                                            locallySelectedApplicationId !==
                                                                undefined
                                                        )
                                                    }
                                                >
                                                    Utilities
                                                </Button>
                                            </ListItem>
                                        </Can>
                                    </List>
                                </SubMenuWrapper>
                                {/* </Collapse> */}
                                {/* <ListItemButton
              onClick={() => setOpenDevices(!openDevices)}
            >
              <ListSubheader component='div' disableSticky>
                Devices
              </ListSubheader>
              {openDevices ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />}
            </ListItemButton> */}
                                {/* <Collapse in={openDevices} timeout='auto' unmountOnExit> */}
                                <SubMenuWrapper>
                                    <List component="div">
                                        <Can I={ACTIONS.READ} a="MeterPoints">
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.meterpoints.path}
                                                    startIcon={
                                                        <ControlPointDuplicate />
                                                    }
                                                >
                                                    Meter Points
                                                </Button>
                                            </ListItem>
                                        </Can>
                                        <Can I={ACTIONS.READ} a="Zones">
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.zones.path}
                                                    startIcon={
                                                        <GroupWorkOutlined />
                                                    }
                                                >
                                                    Zones
                                                </Button>
                                            </ListItem>
                                        </Can>
                                        <Can I={ACTIONS.READ} a="Meters">
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.meters.path}
                                                    startIcon={<Speed />}
                                                >
                                                    Meters
                                                </Button>
                                            </ListItem>
                                        </Can>
                                        <Can I={ACTIONS.READ} a="Gateways">
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.gateways.path}
                                                    startIcon={
                                                        <SettingsInputAntenna />
                                                    }
                                                >
                                                    Gateways
                                                </Button>
                                            </ListItem>
                                        </Can>
                                        {ability.can(ACTIONS.MANAGE, 'all') && (
                                            <Can I={ACTIONS.READ} a="Sensors">
                                                <ListItem component="div">
                                                    <Button
                                                        disableRipple
                                                        component={RouterLink}
                                                        onClick={closeSidebar}
                                                        to={pages.sensors.path}
                                                        startIcon={<Sensors />}
                                                    >
                                                        Sensors
                                                    </Button>
                                                </ListItem>
                                            </Can>
                                        )}

                                        {locallySelectedApplicationGroupId !==
                                            '5fd8e2aa5cdb85aa9a69430f' && (
                                            <Can
                                                I={ACTIONS.MANAGE}
                                                a="UtilityData"
                                            >
                                                <ListItem component="div">
                                                    <Button
                                                        disableRipple
                                                        component={RouterLink}
                                                        onClick={closeSidebar}
                                                        to={
                                                            pages.manualReadings
                                                                .path
                                                        }
                                                        startIcon={
                                                            <DocumentScannerOutlined />
                                                        }
                                                    >
                                                        Manual Readings
                                                    </Button>
                                                </ListItem>
                                            </Can>
                                        )}
                                    </List>
                                </SubMenuWrapper>
                                {/* </Collapse> */}
                                {/* <ListItemButton
              onClick={() => setOpenBilling(!openBilling)}
            >
              <ListSubheader component='div' disableSticky>
                Billing
              </ListSubheader>
              {openBilling ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />}
            </ListItemButton> */}
                                {/* <Collapse in={openBilling} timeout='auto' unmountOnExit> */}
                                <SubMenuWrapper>
                                    <List component="div">
                                        <Can I={ACTIONS.READ} a="Tariffs">
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.tariffs.path}
                                                    startIcon={<RequestQuote />}
                                                >
                                                    Tariffs
                                                </Button>
                                            </ListItem>
                                        </Can>
                                        <Can I={ACTIONS.READ} a="Payments">
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.payments.path}
                                                    startIcon={<Payments />}
                                                >
                                                    Payments
                                                </Button>
                                            </ListItem>
                                        </Can>
                                        <Can I={ACTIONS.READ} a="BillingRuns">
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.billing.path}
                                                    startIcon={
                                                        <MonetizationOn />
                                                    }
                                                >
                                                    Billing
                                                </Button>
                                            </ListItem>
                                        </Can>
                                    </List>
                                </SubMenuWrapper>
                                {/* </Collapse> */}
                                {/* <ListItemButton
              onClick={() => setOpenMore(!openMore)}
            >
              <ListSubheader component='div' disableSticky>
                Additional
              </ListSubheader>
              {openMore ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />}
            </ListItemButton> */}
                                {/* <Collapse in={openMore} timeout='auto' unmountOnExit> */}
                                <SubMenuWrapper>
                                    <List component="div">
                                        <Can
                                            I={ACTIONS.MANAGE}
                                            a="Applications"
                                        >
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.integrations.path}
                                                    startIcon={
                                                        <IntegrationInstructions />
                                                    }
                                                >
                                                    Integrations
                                                </Button>
                                            </ListItem>
                                        </Can>
                                        <Can I={ACTIONS.READ} a="Meters">
                                            <ListItem component="div">
                                                <Button
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={pages.map.path}
                                                    startIcon={<Map />}
                                                >
                                                    Map
                                                </Button>
                                            </ListItem>
                                        </Can>
                                    </List>
                                </SubMenuWrapper>
                                {/* </Collapse> */}
                            </>
                        )}
                </Scrollbar>
            </MenuWrapper>
        </>
    )
}

export default SidebarMenu
